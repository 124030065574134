import React from 'react';

import { ImageAndText } from '../../elements/ImageAndText/ImageAndText';

import classes from './SevenDeals.scss';

export interface InfoItem {
    image: string;
    title: string;
    link: string;
}

type SevenDealsProps = {
    items: InfoItem[];
};

export const SevenDeals: React.FC<SevenDealsProps> = ({ items }) => {
    return (
        <div className={classes.sevenDealsWrapper}>
            <div className={classes.sevenDealsBig}>
                <ImageAndText link={items[0].link} image={items[0].image} titlePositionAbsolute />
            </div>

            <ul className={classes.sevenDealsSmall}>
                {items.map((el, i) => {
                    if (i === 0) {
                        return;
                    }
                    return (
                        <ImageAndText
                            title={el.title}
                            link={el.link}
                            image={el.image}
                            key={el.title}
                            paddingFullWithoutBottom
                            paddingNoOnSides
                        />
                    );
                })}
            </ul>
        </div>
    );
};
