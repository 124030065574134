import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { camelizeKeys } from 'humps';
import classnames from 'classnames';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { HomeMainWidgetsSkeleton } from 'src/elements/Skeletons/Skeletons';

import classes from './HomeMainWidgets.scss';

export interface CarouselItem {
    title: string;
    description: string;
    link: string;
    link_text: string;
    image: string;
    image_mobile: string;
}

const homeGalleryWidget = gql`
    query {
        homeGalleryWidget {
            title
            widget_parameters {
                title
                description
                image
                image_mobile
                button_url
                button_label
            }
        }
    }
`;

export const HomeMainWidgets = () => {
    const { loading, data } = useQuery(homeGalleryWidget);

    const homeGalleryData = camelizeKeys(data?.homeGalleryWidget);

    if (loading) return <HomeMainWidgetsSkeleton />;

    if (!data || !homeGalleryData) return null;

    const getWidgetData = (index: string) => homeGalleryData.find((data) => data.title.includes(index));

    return (
        <div
            className={classnames(classes.wrapper, {
                [classes.marginTop]: Boolean(getWidgetData('1')?.widgetParameters?.buttonLabel),
            })}
        >
            <a href={getWidgetData('1')?.widgetParameters?.buttonUrl || undefined} className={classes.widgetFirst}>
                <LazyImg
                    src={getWidgetData('1')?.widgetParameters?.image}
                    alt="image of first widget"
                    className={classes.image}
                />
                <LazyImg
                    className={classes.imageMobile}
                    src={
                        getWidgetData('1')?.widgetParameters?.imageMobile
                            ? getWidgetData('1')?.widgetParameters?.imageMobile
                            : getWidgetData('1')?.widgetParameters?.image
                    }
                    alt="image of first widget"
                />
            </a>

            <a className={classes.widgetSecond} href={getWidgetData('2')?.widgetParameters?.buttonUrl || undefined}>
                <div className={classes.content}>
                    <LazyImg
                        className={classnames(classes.image, classes.wideImage)}
                        src="../../../static/img/hh.svg"
                        alt="image of second widget"
                    />
                    <div className={classes.sideWidgetContainer}>
                        <h3 className={classes.sideWidgetTitle}>{getWidgetData('2')?.widgetParameters?.title}</h3>
                        <p className={classes.sideWidgetDescription}>
                            {getWidgetData('2')?.widgetParameters?.description}
                        </p>
                    </div>
                </div>
                <div>
                    <span className={classes.sideWidgetLinkContainer}>
                        <span className={classes.sideWidgetLink}>Shop now</span>
                        <img src="../../../static/icons/arrow-right-white.svg" />
                    </span>
                </div>
            </a>
            <a className={classes.widgetThird} href={getWidgetData('3')?.widgetParameters?.buttonUrl || undefined}>
                <LazyImg
                    className={classes.image}
                    src={getWidgetData('3')?.widgetParameters?.image}
                    alt="image of third widget"
                />
                <LazyImg
                    className={classes.imageMobile}
                    src={
                        getWidgetData('3')?.widgetParameters?.imageMobile
                            ? getWidgetData('3')?.widgetParameters?.imageMobile
                            : getWidgetData('3')?.widgetParameters?.image
                    }
                    alt="image of third widget"
                />
            </a>
            <a className={classes.widgetFourth} href={getWidgetData('4')?.widgetParameters?.buttonUrl || undefined}>
                <LazyImg
                    className={classes.image}
                    src={getWidgetData('4')?.widgetParameters?.image}
                    alt="image of fourth widget"
                />
                <LazyImg
                    className={classes.imageMobile}
                    src={
                        getWidgetData('4')?.widgetParameters?.imageMobile
                            ? getWidgetData('4')?.widgetParameters?.imageMobile
                            : getWidgetData('4')?.widgetParameters?.image
                    }
                    alt="image of fourth widget"
                />
            </a>
            <a className={classes.widgetFifth} href={getWidgetData('5')?.widgetParameters?.buttonUrl || undefined}>
                <LazyImg
                    className={classes.image}
                    src={getWidgetData('5')?.widgetParameters?.image}
                    alt="image of fifth widget"
                />
                <LazyImg
                    className={classes.imageMobile}
                    src={
                        getWidgetData('5')?.widgetParameters?.imageMobile
                            ? getWidgetData('5')?.widgetParameters?.imageMobile
                            : getWidgetData('5')?.widgetParameters?.image
                    }
                    alt="image of fifth widget"
                />
                {getWidgetData('5')?.widgetParameters?.title && (
                    <div className={classes.blurContainer}>
                        <img
                            src={
                                getWidgetData('5')?.widgetParameters?.imageMobile
                                    ? getWidgetData('5')?.widgetParameters?.imageMobile
                                    : getWidgetData('5')?.widgetParameters?.image
                            }
                            className={classes.blurImage}
                            alt="image of fifth widget"
                        />
                        <h3 className={classes.title}>{getWidgetData('5')?.widgetParameters?.title}</h3>
                        <img src="../../../static/icons/v-right_orange.svg" />
                    </div>
                )}
            </a>
            <a className={classes.widgetSixth} href={getWidgetData('6')?.widgetParameters?.buttonUrl || undefined}>
                <LazyImg
                    className={classes.image}
                    src={getWidgetData('6')?.widgetParameters?.image}
                    alt="image of sixth widget"
                />
                <LazyImg
                    className={classes.imageMobile}
                    src={
                        getWidgetData('6')?.widgetParameters?.imageMobile
                            ? getWidgetData('6')?.widgetParameters?.imageMobile
                            : getWidgetData('6')?.widgetParameters?.image
                    }
                    alt="image of sixth widget"
                />
                {getWidgetData('6')?.widgetParameters?.title && (
                    <div className={classes.blurContainer}>
                        <h3 className={classes.title}>{getWidgetData('6')?.widgetParameters?.title}</h3>
                        <img src="../../../static/icons/v-right_orange.svg" />
                    </div>
                )}
            </a>
        </div>
    );
};
