import React from 'react';
import { ShopNowButton } from 'src/components/ShopNowButton/ShopNowButton';
import { LazyImg } from 'src/hooks/useLazyLoad';

import classes from './HomeInfo.scss';

export interface InfoItem {
    title: string;
    description: string;
    image: string;
    image1: string;
    image2: string;
    image3: string;
    link: string;
}

type HomeInfoProps = {
    items: InfoItem[];
};

export const HomeInfo: React.FC<HomeInfoProps> = ({ items }) => (
    <div className={classes.homeInfo}>
        {items.map((item) => (
            <div key={item.title} className={classes.infoBlock}>
                <div className={classes.infoBlockContent}>
                    <div>
                        <h2 className={classes.infoBlockTitle} dangerouslySetInnerHTML={{ __html: item.title || '' }} />
                        <p
                            className={classes.infoBlockDescription}
                            dangerouslySetInnerHTML={{ __html: item.description || '' }}
                        />
                        <ShopNowButton link={item.link} />
                    </div>
                </div>
                <div className={classes.infoBlockImagesContainer}>
                    <div className={classes.infoBlockImages}>
                        <div className={classes.image}>
                            <LazyImg src={item.image} alt="image 1" />
                        </div>
                        <div className={classes.image1}>
                            <LazyImg src={item.image1} alt="image 2" />
                        </div>
                        <div className={classes.image2}>
                            <LazyImg src={item.image2} alt="image 3" />
                        </div>
                        <div className={classes.image3}>
                            <LazyImg src={item.image3} alt="image 4" />
                        </div>
                    </div>
                    <div className={classes.wweLogoMobile}>
                        <img src={require('../../svg/wwe-logo-mobile.svg')} alt="wwe logo" />
                    </div>
                </div>
            </div>
        ))}
        <div className={classes.wweLogoDesktop}>
            <img src={require('../../svg/wwe-logo.svg')} alt="wwe logo" />
        </div>
    </div>
);
