import { DataStatus, EpicDeps, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { errorMessage, successMessage } from 'src/util/state-helpers';
import { useDeps } from 'src/hooks/useDeps';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { useCallback, useEffect, useReducer } from 'react';
import { defer, EMPTY, of, Subject } from 'rxjs';
import { catchError, filter, mapTo, switchMap, tap } from 'rxjs/operators';
import { subscribeNewsletter } from 'src/queries/__generated__/subscribeNewsletter';
import newsletterQuery from 'src/queries/subscribeNewsletter.graphql';
import { ApolloClient } from 'apollo-client';
import Bugsnag from '@bugsnag/js';

/**
 * Wrapper for the contact form side-effects
 */

export type NewsletterState = {
    status: DataStatus;
    messages: Message[];
};

const initialState: NewsletterState = {
    status: DataStatus.Idle,
    messages: [],
};

type NewsletterMsg =
    | {
          kind: 'submit';
          email: string;
      }
    | {
          kind: 'error';
          message: string;
      }
    | {
          kind: 'success';
          message: string;
      };

function newsletterReducer(state = initialState, action: NewsletterMsg): NewsletterState {
    switch (action.kind) {
        case 'submit': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'error': {
            return {
                ...state,
                status: DataStatus.Error,
                messages: [errorMessage(action.message)],
            };
        }
        case 'success': {
            return {
                ...state,
                status: DataStatus.Success,
                messages: [successMessage(action.message)],
            };
        }
        default:
            return state;
    }
}

export function useNewsletter() {
    const deps = useDeps();
    const [state, dispatch] = useReducer(newsletterReducer, initialState);

    const mirror$ = useConstant(() => new Subject<NewsletterMsg>());

    useEffect(() => {
        const sub = mirror$
            .pipe(
                filter((x) => x.kind === 'submit'),
                switchMap((msg) => {
                    if (msg.kind === 'submit') {
                        return newsletterMutation(deps, msg.email).pipe(
                            mapTo({ kind: 'success', message: 'Signed up!' }),
                            catchError((e) => {
                                Bugsnag.notify(e);
                                const strippedMsg = e.message.replace('GraphQL error:', '');
                                return of({ kind: 'error', message: strippedMsg });
                            }),
                        );
                    }
                    return EMPTY;
                }),
                tap((msg) => dispatch(msg as NewsletterMsg)),
            )
            .subscribe();
        return () => {
            sub.unsubscribe();
        };
    }, [deps, mirror$]);

    const submit = useCallback(
        (email: string) => {
            const msg = { kind: 'submit' as const, email };
            dispatch(msg);
            mirror$.next(msg);
        },
        [mirror$],
    );

    return {
        state,
        submit,
    };
}

function newsletterMutation(deps: EpicDeps, email: string) {
    return defer(() =>
        (deps.client as ApolloClient<any>).mutate<subscribeNewsletter>({
            mutation: newsletterQuery,
            variables: {
                email: email,
            },
            fetchPolicy: 'no-cache',
        }),
    );
}
