import React, { useState } from 'react';
import { RichText } from 'src/components/RichText/RichText';
import { Svg } from 'src/components/Svg/Svg';
import classnames from 'classnames';
import { useTreatments } from '@splitsoftware/splitio-react';
import { CATEGORY_FOOTER } from 'src/constants/splitFlag';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { CustomLink } from 'src/components/CustomLink/CustomLink';
import { CarouselWithOrangeBlock } from 'src/components/CarouselWithOrangeBlock/CarouselWithOrangeBlock';

import classes from './CategoryList.scss';

export interface CategoryItem {
    image: string;
    link: string;
    title: string;
}

type CategoryListProps = {
    categories: CategoryItem[];
};

export const CategoryList: React.FC<CategoryListProps> = ({ categories }) => {
    let titleArray, descriptionArray;

    const [isOpen, setIsOpen] = useState(
        titleArray ? titleArray.map((title, index) => ({ id: index, value: false })) : undefined,
    );

    const updateArrayElement = (id: number, value: boolean) => {
        setIsOpen((prevArray) =>
            prevArray.map((item) => {
                if (item.id === id) {
                    return { ...item, value: value };
                }
                return item;
            }),
        );
    };

    const treatmentsFooter = useTreatments([CATEGORY_FOOTER]);

    const { treatment: treatmentFooter } = treatmentsFooter[CATEGORY_FOOTER];

    return (
        <>
            <div className={classes.categoryList} data-test-id="categoryList">
                <h2>Shop by Category</h2>
                {categories.length > -1 && (
                    <CarouselWithOrangeBlock orangeTitle="Shop by Category" additionalBlock elements={categories} />
                )}
                <CustomLink link="/clearance" className={classes.clearance}>
                    <LazyImg
                        className={classes.image}
                        src={require('../../../static/img/clearance.png')}
                        alt="clearance image"
                    />
                    <span>
                        <span className={classes.sideWidgetLink}>Shop Clearance & Special Offers</span>
                        <img src="../../../static/icons/orange-arrow-right.svg" />
                    </span>
                </CustomLink>
            </div>
            {treatmentFooter === 'on' && titleArray && (
                <div>
                    {Boolean(titleArray.length > 0 && isOpen) && (
                        <div className={classes.extendedDescription}>
                            {titleArray.map((title, index) => (
                                <div
                                    key={index}
                                    className={classnames(classes.content, {
                                        [classes.active]: isOpen[index].value,
                                    })}
                                >
                                    <button
                                        type="button"
                                        onClick={() => updateArrayElement(index, !isOpen[index].value)}
                                    >
                                        <h3>{title}</h3>
                                        <Svg name={isOpen[index].value ? 'arrow-up' : 'arrow-down'} />
                                    </button>
                                    {descriptionArray[index] && (
                                        <p>
                                            <RichText content={descriptionArray[index]} />
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
