import React from 'react';
import { CustomLink } from 'src/components/CustomLink/CustomLink';
import { ShopNowButton } from 'src/components/ShopNowButton/ShopNowButton';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { camelizeKeys } from 'humps';
import { usePathname } from 'src/hooks/router-hooks';
import { FooterNewsletterConnected } from 'src/components/Footer/FooterNewsletter';

import { LazyImg } from '../../hooks/useLazyLoad';
import { stripDomain } from '../../util/sanitizeLink';

import classes from './TrustedBy.scss';

export type TrustedByItem = {
    image?: string | null;
    link?: string | null;
};

type TrustedByProps = {
    items: TrustedByItem[];
};

const trustedByIntroWidget = gql`
    query {
        trustedByIntroWidget {
            title
            widget_parameters {
                title
                main_title
                description
                link
            }
        }
    }
`;

export const TrustedBy: React.FC<TrustedByProps> = ({ items }) => {
    const pathname = usePathname();
    const addNewsletter = pathname.includes('personalised-workwear');

    const trustedItems = [items.slice(0, 6), items.slice(6, 11)];

    const { loading, data } = useQuery(trustedByIntroWidget);

    if (loading || !data) return null;

    const trustedByData = camelizeKeys(data?.trustedByIntroWidget?.[0]).widgetParameters;

    return (
        <div className={classes.trustedBy}>
            <h2 className={classes.trustedByTitle}>{trustedByData?.mainTitle}</h2>
            <div className={classes.trustedByContainer}>
                <div className={classes.trustedByElements}>
                    {trustedItems.map((array, index) => (
                        <TrustedItem array={array} key={index} />
                    ))}
                </div>
                <div className={classes.trustedByDescription}>
                    <div>
                        <h3>{trustedByData?.title}</h3>
                        <p>{trustedByData?.description}</p>
                        <ShopNowButton link={trustedByData?.link} />
                    </div>
                </div>
            </div>
            {addNewsletter && <FooterNewsletterConnected mobileOnly />}
        </div>
    );
};

const TrustedItem = ({ array }: { array: TrustedByItem[] }) => (
    <>
        {array.map((item, childIndex) => (
            <CustomLink link={stripDomain(item.link)} key={childIndex} className={classes.trustedByLink}>
                {item.image && <LazyImg src={item.image} />}
            </CustomLink>
        ))}
    </>
);
