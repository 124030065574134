import React from 'react';
import classnames from 'classnames';
import { NUMBER_OF_SLIDES_TO_SHOW } from 'src/constants/product';

import upsellClasses from '../../components/Basket/upsell/BasketUpsellItem.scss';

import classes from './Arrow.scss';

const _string = require('lodash/string');

export const getUrlForBrand = (label: string) =>
    `/brands/${_string.replace(label.toLowerCase(), new RegExp(' ', 'g'), '-')}`;

type ArrowDirection = 'left' | 'right';
type ArrowProps = {
    onClick?: () => void;
    direction: ArrowDirection;
    color?: 'orange' | 'white';
    blogButton?: boolean;
    space?: boolean;
    black?: boolean;
} & JSX.IntrinsicElements['button'];

const mappedDirection: { [key in ArrowDirection]: string } = {
    left: classes.arrow__left,
    right: classes.arrow__right,
};

const mappedUpsellDirection: { [key in ArrowDirection]: string } = {
    left: upsellClasses.arrow__left,
    right: upsellClasses.arrow__right,
};

export const Arrow: React.FC<ArrowProps> = ({
    onClick,
    direction,
    color,
    className,
    space = false,
    blogButton,
    black = false,
}) => {
    const makeDisabled = Boolean(className?.includes('slick-disabled'));

    return (
        <button
            type="button"
            className={classnames(
                { [classes.disabled]: makeDisabled, [classes.blogNavButton]: blogButton },
                { [classes.space]: space },
                { [classes.black]: black },
                classes.carouselNavButton,
                mappedDirection[direction],
            )}
            onClick={onClick}
        >
            <img src={require(`../../../static/icons/v-${direction}${color ? `_${color}` : ''}.svg`)} alt={direction} />
        </button>
    );
};

const UpsellArrow: React.FC<ArrowProps> = (props) => {
    const { onClick, direction, className } = props;

    const makeDisabled = Boolean(className?.includes('slick-disabled'));

    return (
        <button
            type="button"
            className={classnames(
                { [upsellClasses.disabled]: makeDisabled },
                upsellClasses.carouselNavButton,
                mappedUpsellDirection[direction],
            )}
            onClick={onClick}
        >
            <img src={require(`../../../static/icons/v-${direction}.svg`)} alt={direction} />
        </button>
    );
};

export const productCarouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: NUMBER_OF_SLIDES_TO_SHOW,
    slidesToScroll: 3,
    initialSlide: 0,
    dots: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
    nextArrow: <Arrow direction="right" color="orange" space />,
    prevArrow: <Arrow direction="left" space />,
};

export const carouselHomeSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
    nextArrow: <Arrow direction="right" color="orange" />,
    prevArrow: <Arrow direction="left" />,
};

export const carouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
    nextArrow: <Arrow direction="right" color="orange" />,
    prevArrow: <Arrow direction="left" />,
};

export const blogCarouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1109,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 899,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 599,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
    nextArrow: <Arrow direction="right" color="orange" blogButton />,
    prevArrow: <Arrow direction="left" blogButton />,
};

export const upsellCarouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: false,
    responsive: [
        {
            breakpoint: 1222,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 1070,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 899,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 749,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                swipe: true,
            },
        },
    ],
    nextArrow: <UpsellArrow direction="right" />,
    prevArrow: <UpsellArrow direction="left" />,
};
