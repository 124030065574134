import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { usePhotoswipeForGallery } from 'src/hooks/useProductGallery';
import { LazyImg } from 'src/hooks/useLazyLoad';
import classnames from 'classnames';

import { ImageAndText } from '../ImageAndText/ImageAndText';

import { getUrlForBrand, carouselHomeSettings, carouselSettings } from './settings';
import classes from './Carousel.scss';

type BrandCarouselItem = {
    label?: string | null;
    title?: string | null;
    image?: string | null;
};
type BrandCarouselProps = {
    items: BrandCarouselItem[];
    mainTitle: string;
    shouldRenderLinkToBrands?: boolean;
    showZoom?: boolean;
    paddingFull?: boolean;
    moreBlocks?: boolean;
    isWWEBackground?: boolean;
};

export const Carousel: React.FC<BrandCarouselProps> = ({
    items,
    mainTitle,
    shouldRenderLinkToBrands,
    showZoom,
    paddingFull = true,
    moreBlocks = false,
    isWWEBackground = false,
}) => {
    const images = items.map((item, index) => ({
        large_url: item.image || '',
        large_height: 1200,
        large_width: 1200,
        position: index,
    }));
    const [callPhotoSwipe, setCallPhotoSwipe] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const { showPhotoswipe, photoswipeReady, photoSwipeRef, photoswipeHtml } = usePhotoswipeForGallery({
        selectedIndex: selectedImageIndex || 0,
        sortedMediaEntries: images,
    });
    const onZoomClick = (index: number) => {
        setSelectedImageIndex(index);
        setCallPhotoSwipe(true);
    };
    useEffect(() => {
        if (callPhotoSwipe) {
            showPhotoswipe();
            setCallPhotoSwipe(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImageIndex]);

    const settings = moreBlocks ? carouselHomeSettings : carouselSettings;

    return (
        <div
            className={classnames(classes.carouselWrapper, {
                [classes.carouselWrapperWithBackground]: isWWEBackground,
            })}
        >
            {isWWEBackground && (
                <div className={classes.background}>
                    <LazyImg className={classes.desktop} src="../../../static/img/wwe.png" alt="WWE background" />
                    <LazyImg className={classes.mobile} src="../../../static/img/wwe-mobile.png" alt="WWE background" />
                </div>
            )}
            <div className={classes.carouselNav}>
                <div className={classes.carouselNavLeft}>
                    <h2>{mainTitle}</h2>
                    {shouldRenderLinkToBrands && (
                        <div className={classes.carouselNavLink}>
                            <Link to="/brands">View all</Link>
                            <img src="../../../static/icons/v-right_orange.svg" />
                        </div>
                    )}
                </div>
            </div>

            {photoswipeReady && <div dangerouslySetInnerHTML={{ __html: photoswipeHtml }} ref={photoSwipeRef} />}
            <Slider {...settings}>
                {items?.map((item, index) => (
                    <div key={index}>
                        <div className={classes.carouselElementContainer}>
                            <ImageAndText
                                link={item.label ? getUrlForBrand(item.label || '/') : null}
                                image={item.image}
                                paddingFullWithoutBottom={Boolean(item.label)}
                                paddingFull={!Boolean(item.label) && paddingFull}
                                withoutBackground
                            />
                            {showZoom && (
                                <button
                                    type="button"
                                    aria-label="Zoom"
                                    className={classes.zoomButton}
                                    onClick={() => onZoomClick(index)}
                                ></button>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};
