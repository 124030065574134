import React from 'react';
import { LazyImg } from 'src/hooks/useLazyLoad';
import classnames from 'classnames';
import { CustomLink } from 'src/components/CustomLink/CustomLink';

import classes from './ImageAndText.scss';

enum TitlePlace {
    left = 'left',
    center = 'center',
    right = 'reight',
}

const defaultLabel = 'Default label';

type ImageAndTextProps = {
    title?: string | null;
    link?: string | null;
    image?: string | null;
    paddingFull?: boolean;
    paddingFullWithoutBottom?: boolean;
    paddingNoOnSides?: boolean;
    titlePlace?: string;
    imageHalfWidth?: boolean;
    imageMaxHeight210?: boolean;
    titlePositionAbsolute?: boolean;
    withoutBackground?: boolean;
    fullHeight?: boolean;
};

export const ImageAndText: React.FC<ImageAndTextProps> = ({
    title,
    link,
    image,
    paddingFull,
    paddingFullWithoutBottom,
    paddingNoOnSides,
    titlePlace,
    titlePositionAbsolute,
    imageHalfWidth,
    withoutBackground = false,
    fullHeight = false,
}) => (
    <li
        className={classnames(classes.wrapper, {
            [classes.withoutBackground]: withoutBackground,
        })}
    >
        <CustomLink
            className={classnames(classes.link, {
                [classes.fullHeight]: fullHeight,
            })}
            link={link}
        >
            <picture
                className={classnames(classes.imageWrapper, {
                    [classes.imageWrapperpaddingFullWithoutBottom]: paddingFullWithoutBottom,
                    [classes.imageWrapperPaddingFull]: paddingFull,
                })}
            >
                {image && (
                    <div className={classes.imageContainer}>
                        <DefaultImage imageHalfWidth={imageHalfWidth} />
                        <LazyImg
                            className={classnames(classes.image, {
                                [classes.imageHalfWidth]: imageHalfWidth,
                            })}
                            src={image}
                            alt={title || defaultLabel}
                        />
                    </div>
                )}
            </picture>
            {title && (
                <h3
                    className={classnames(classes.title, {
                        [classes.titleLeft]: titlePlace === TitlePlace.left,
                        [classes.titleCenter]: titlePlace === TitlePlace.center,
                        [classes.titleRight]: titlePlace === TitlePlace.right,
                        [classes.titlePositionAbsolute]: titlePositionAbsolute,
                        [classes.paddingNoOnSides]: paddingNoOnSides,
                    })}
                >
                    {title}
                </h3>
            )}
        </CustomLink>
    </li>
);

export const DefaultImage: React.FC<{ imageHalfWidth?: boolean }> = ({ imageHalfWidth }) => {
    const img = require('../../../static/default-image.png');

    return (
        <img
            src={img}
            alt="Default image"
            className={classnames(classes.defaultImage, {
                [classes.defaultImageHalfWidth]: imageHalfWidth,
            })}
        />
    );
};
