import React from 'react';
import classnames from 'classnames';
import { Form, Text } from '@wearejh/rx-form';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { StockState } from 'src/components/StockNotify/StockNotify';
import { Messages } from 'src/components/Messages/Messages';
import { useNewsletter } from 'src/components/Footer/hooks/useNewsletter';

import { Button } from '../Button/Button';
import { ErrorFor } from '../Forms/ErrorFor';
import { basicEmailValidation } from '../../util/forms';

import classes from './FooterNewsletter.scss';

type FooterNewsletterProps = {
    state: StockState;
    submit(email: string): void;
    mobileOnly?: boolean;
};

export const FooterNewsletter: React.FC<FooterNewsletterProps> = ({ state, submit, mobileOnly }) => {
    const isPending = state.status === DataStatus.Pending;

    return (
        <div
            className={classnames(classes.container, {
                [classes.mobileOnly]: mobileOnly,
            })}
            style={{ backgroundImage: "url('../../static/img/transparent-workwear-logo.png')" }}
        >
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>Join the Workwear Newsletter</h2>
                <span className={classes.subtitle}>
                    It’s free and you’ll be the first to know about our latest <br /> offers, news and discounts.
                </span>
            </div>

            <Form
                className={classes.footerNewsletter}
                onSubmit={(values) => submit(values.nlEmail)}
                data-test-id="NewsLetterForm"
            >
                <div className={classes.footerNewsletterForm}>
                    <div className={classes.footerNewsletterField}>
                        <Text
                            className={classes.footerNewsletterInput}
                            field="nlEmail"
                            id="nlEmail"
                            validate={basicEmailValidation()}
                            placeholder="Enter your email address"
                            disabled={isPending}
                            data-test-id="NewsLetterInput"
                        />
                        <Button className={classes.footerNewsletterButton} type="submit" disabled={isPending}>
                            Sign me up
                        </Button>
                    </div>

                    <ErrorFor field="nlEmail" />
                    {state.messages.length > 0 && (
                        <div className={classes.footerNewsletterMessages} data-test-id="NewsLetterMessages">
                            <Messages messages={state.messages} />
                        </div>
                    )}
                </div>
            </Form>
        </div>
    );
};

type NewsletterConnectedProps = {
    mobileOnly?: boolean;
};

export const FooterNewsletterConnected: React.FC<NewsletterConnectedProps> = ({ mobileOnly }) => {
    const { state, submit } = useNewsletter();

    return <FooterNewsletter state={state} submit={submit} mobileOnly={mobileOnly} />;
};
