import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import lozad from 'lozad';
import { PhotoSwipeEventName, usePhotoswipe } from 'src/hooks/usePhotoswipe/usePhotoswipe';
import { MediaEntry } from 'src/types/global-types';

const FADE_TIMEOUT = 200;

export function useProductGallery(media_entries: MediaEntry[]) {
    const hasThumbs = media_entries.length > 1;
    const thumbsRef = useRef<HTMLUListElement>();
    const mainRef = useRef<HTMLImageElement>();

    const [selectedIndex, setSelected] = useState(0);
    const selector = '[data-gallery-img]';

    /**
     * Make sure every gallery always begins with the first index
     */
    useEffect(() => {
        setSelected(0);
    }, [media_entries]);

    /**
     * Because images are lazy-loaded by lozad, we cannot just
     * replace the images without removing event-listeners etc.
     */
    useEffect(() => {
        if (!thumbsRef.current) return undefined;
        const current = thumbsRef.current;
        const thumbs = thumbsRef.current.querySelectorAll('[data-src]');
        const observer = lozad(thumbs);
        observer.observe();
        return () => {
            if (current) {
                [].forEach.call(current.querySelectorAll('[data-src]'), function (elem) {
                    observer.observer.unobserve(elem);
                });
            }
        };
    }, [selector, media_entries]);

    /**
     * Like the above, this work-around is to ensure we can still use
     * lazy-loading with gallery swapping.
     */
    useEffect(() => {
        if (!mainRef.current) return undefined;
        let observer;
        const current = mainRef.current;
        current.classList.add('fade-out');
        current.removeAttribute('data-loaded');
        const timeout = setTimeout(() => {
            if (current.src) {
                current.src = '';
            }
            observer = lozad(current, {
                loaded: function () {
                    if (current) {
                        current.classList.remove('fade-out');
                    }
                },
            });
            observer.observe();
        }, FADE_TIMEOUT);
        return () => {
            clearTimeout(timeout);
            if (current && observer && observer.observer) {
                observer.observer.unobserve(current);
            }
        };
    }, [media_entries, selectedIndex]);

    /**
     * Sort the media entries and return for everyone
     * to use.
     */
    const sortedMediaEntries = useMemo(() => {
        return media_entries.slice().sort((a, b) => a.position - b.position);
    }, [media_entries]);

    /**
     * The 'selected' image is a simple index lookup
     */
    const selected = sortedMediaEntries[selectedIndex];

    return {
        hasThumbs,
        selected,
        mainRef,
        thumbsRef,
        sortedMediaEntries,
        selectedIndex,
        setSelected,
    };
}

interface PhotoSwipeParams {
    selectedIndex: number;
    sortedMediaEntries: MediaEntry[];
}

/**
 * @param params
 */
export function usePhotoswipeForGallery(params: PhotoSwipeParams) {
    const { selectedIndex, sortedMediaEntries } = params;
    const photoSwipeRef = useRef(null);
    const [msg, dispatch] = usePhotoswipe(photoSwipeRef);

    const showPhotoswipe = useCallback(() => {
        dispatch({
            index: selectedIndex,
            images: sortedMediaEntries.map((img) => {
                return {
                    h: img.large_height,
                    w: img.large_width,
                    src: img.large_url,
                    title: undefined,
                };
            }),
        });
    }, [dispatch, selectedIndex, sortedMediaEntries]);

    return {
        showPhotoswipe,
        photoswipeReady: msg.type === PhotoSwipeEventName.Ready,
        photoswipeHtml: (msg.type === PhotoSwipeEventName.Ready && msg.html) || '',
        photoSwipeRef,
    };
}
