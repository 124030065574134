import React, { useCallback } from 'react';
import { catchError, ignoreElements, mergeMap, subscribeOn } from 'rxjs/operators';
import { asyncScheduler, concat, defer, EMPTY, forkJoin, Observable, of } from 'rxjs';
import Bugsnag from '@bugsnag/js';

import { useObservableValue } from '../useObservableValue';

export type PhotoSwipeImage = {
    src: string;
    w: number;
    h: number;
    msrc?: string;
    title?: string;
};

export type PhotoSwipeOpen = {
    images: PhotoSwipeImage[];
    index: number;
};

export enum PhotoSwipeEventName {
    Idle = 'idle',
    Loading = 'loading',
    Ready = 'ready',
}

export type PhotoSwipeEvent =
    | {
          type: PhotoSwipeEventName.Idle;
      }
    | {
          type: PhotoSwipeEventName.Loading;
          index: number;
      }
    | {
          type: PhotoSwipeEventName.Ready;
          html: string;
      };

/**
 *
 * Use Photoswipe Hook
 *
 *
 * const [ msg, dispatch ] = usePhotoswipe(ref, {imageSelector: "[data-gallery-main]"});
 *
 * return (
 *      <>
 *          {msg.type === PhotoSwipeEventName.Ready && <div dangerouslySetInnerHTML={{ __html: msg.html }} ref={ref} />}
 *          <img className={classes.image} data-src={mainImg} alt={product.name} data-gallery-main />
 *          <button type="button" onClick={() => dispatch({
 *              dispatch({
 *                   index: 0,
 *                   images: product.media_entries.map(img =>{
 *                       return {
 *                           h: img.large_height,
 *                           w: img.large_width,
 *                           msrc: img.medium_url,
 *                           src: img.large_url,
 *                           title: undefined
 *                       }
 *                   })
 *               })
 *          })}>
 *
 *          </button>
 *
 *      </>
 * )
 *
 * @param ref
 * @param options
 */
export function usePhotoswipe(ref: React.RefObject<HTMLElement>): [PhotoSwipeEvent, (input: PhotoSwipeOpen) => void] {
    const cb = useCallback(
        ($values: Observable<PhotoSwipeOpen>) => {
            return $values.pipe(
                mergeMap((input) => {
                    return concat(of({ type: PhotoSwipeEventName.Loading }), loadPhotoswipe(input, ref));
                }),
            );
        },
        [ref],
    );
    return useObservableValue<PhotoSwipeOpen, any>(cb, { type: PhotoSwipeEventName.Idle }, 'usePhotoswipe');
}

function loadPhotoswipe(input: PhotoSwipeOpen, ref: React.RefObject<HTMLElement>): Observable<any> {
    return loadAssets().pipe(
        mergeMap(([Photoswipe, PhotoSwipeUI_Default, html]) => {
            return concat(
                of({ type: PhotoSwipeEventName.Ready, html: html }),
                defer(() => {
                    return Observable.create((obs) => {
                        if (!ref || !ref.current) {
                            Bugsnag.notify('ref missing');
                            return obs.complete();
                        }

                        const pswpElement = ref.current.children[0];

                        const pswOptions = {
                            history: false,
                            index: input.index,
                            shareEl: false,
                            fullscreenEl: false,
                            showHideOpacity: true,
                            zoomEl: true,
                        };

                        const gallery = new Photoswipe.default(
                            pswpElement,
                            PhotoSwipeUI_Default.default,
                            input.images,
                            pswOptions,
                        );
                        document.body.classList.add('modal-open');
                        gallery.listen('close', () => {
                            setTimeout(() => document.body.classList.remove('modal-open'), 100);
                        });
                        gallery.init();
                        return () => {
                            // gallery && gallery.destroy();
                        };
                    });
                }).pipe(subscribeOn(asyncScheduler), ignoreElements()),
            );
        }),
        catchError((e) => {
            Bugsnag.notify('photoswipe failed to load', (event) => {
                event.addMetadata('errorObject', e);
            });
            return EMPTY;
        }),
    );
}

/**
 * Lazily load all of the image assets
 */
function loadAssets(): Observable<[any, any, string, any, any]> {
    return forkJoin([
        defer(() => import(/* webpackChunkName:"__lazy__photoswipe" */ 'photoswipe/dist/photoswipe.js')),
        defer(
            () =>
                import(
                    /* webpackChunkName:"__lazy__photoswipe-ui-default" */ 'photoswipe/dist/photoswipe-ui-default.js'
                ),
        ),
        defer(() =>
            of(
                require(/* webpackChunkName:"__lazy__photoswipe-html" */ 'raw-loader!./photoswipe/photoswipe.html')
                    .default,
            ),
        ),
        defer(() => import(/* webpackChunkName:"__lazy__photoswipe-css" */ './photoswipe/photoswipe.scss')),
        defer(
            () =>
                import(
                    /* webpackChunkName:"__lazy__photoswipe-default-skin-css" */ './photoswipe/default-skin/default-skin.scss'
                ),
        ),
    ]);
}
