import React from 'react';
import { Button } from 'src/components/Button/Button';

import classes from './ShopNowButton.scss';

type ShopNowButtonProps = {
    link: string;
};

export const ShopNowButton: React.FC<ShopNowButtonProps> = ({ link }) => (
    <Button element="a" variant="centered" to={link} iconSvg="v-right.svg" className={classes.button}>
        Shop Now
    </Button>
);
