import React from 'react';
import { LazyPicture } from 'src/hooks/useLazyLoad';
import { Link } from 'react-router-dom';

import classes from './HomeDeals.scss';

export interface Deal {
    imageDesk: string;
    imageMob: string;
    link: string;
    title: string;
}

type HomeDealsProps = {
    deals: Deal[];
};

export const HomeDeals: React.FC<HomeDealsProps> = React.memo(({ deals }) => {
    return (
        <div className={classes.deals}>
            {deals.map((item, index) => {
                return (
                    <div key={index} className={classes.dealsItem}>
                        <Link to={item.link}>
                            <figure className={classes.dealsFigure}>
                                <LazyPicture data-alt={item.title} className={classes.dealsImage} data-lazy-load>
                                    <source media="(min-width: 750px)" srcSet={item.imageDesk} />
                                    <source media="(max-width: 750px)" srcSet={item.imageMob} />
                                </LazyPicture>
                            </figure>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
});
