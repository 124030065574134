import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { camelizeKeys } from 'humps';
import { REASONS_TO_SHOP_TITLE } from 'src/constants/widgets';

import classes from './ReasonsToShopWithUs.scss';

const reasonsToShopWidget = gql`
    query {
        reasonsToShopWidget {
            title
            widget_parameters {
                title
                description
                image
                link
            }
        }
    }
`;

export const ReasonsToShopWithUs = () => {
    const { loading, data } = useQuery(reasonsToShopWidget);

    if (loading || !data) return null;

    const camelizeReasonToShop = camelizeKeys(data?.reasonsToShopWidget);

    const reasonsToShopData = camelizeReasonToShop.filter((el) => el.title !== REASONS_TO_SHOP_TITLE);

    const reasonsTitle = camelizeReasonToShop.find((el) => el.title === REASONS_TO_SHOP_TITLE);

    return (
        <div className={classes.reasonsToShopWithUs}>
            <div className={classes.header}>
                <h2 className={classes.title}>{reasonsTitle?.widgetParameters?.title}</h2>
                <p>{reasonsTitle?.widgetParameters?.description}</p>
                <a href={reasonsTitle?.widgetParameters?.link}>
                    <span>Read More</span>
                    <img src="../../../static/icons/v-right_orange.svg" />
                </a>
            </div>

            <div className={classes.elements}>
                {reasonsToShopData.map((element, key) => (
                    <div className={classes.element} key={key}>
                        <a href={element.widgetParameters.link}>
                            <img src={element.widgetParameters.image} alt={`image ${key}`} />
                            <h3>{element.widgetParameters.title}</h3>
                            <p className={classes.elementDescription}>{element.widgetParameters.description}</p>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};
