import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
    link?: string | null;
    className?: string;
};

export const CustomLink: React.FC<Props> = ({ link, className, children }) =>
    link ? (
        <Link to={link} className={className}>
            {children}
        </Link>
    ) : (
        <span className={className}>{children}</span>
    );
