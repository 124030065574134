import { imagePath } from 'src/util/formatUrl';
import { stripDomain } from 'src/util/sanitizeLink';

import { CategoryItem } from '../CategoryList/CategoryList';

import {
    widgets_carouselWidget,
    widgets_carouselWidget_widget_parameters,
    widgets_featuredCategoryWidget,
    widgets_featuredCategoryWidget_widget_parameters,
    widgets_homepageCta,
    widgets_infoBlockWidget,
    widgets_infoBlockWidget_widget_parameters,
    widgets_teaserWidget,
    widgets_teaserWidget_widget_parameters,
    widgets_trustedByWidget,
    widgets_trustedByWidget_widget_parameters,
    widgets_brandWidget_widget_parameters,
    widgets_brandWidget,
    widgets_thumbWidget,
} from './queries/__generated__/widgets';
import { CarouselItem } from './HomeMainWidgets';
import { Teaser } from './HomeTeasers';
import { InfoItem } from './HomeInfo';
import { Deal } from './HomeDeals';

export function verifyCarousel(items: widgets_carouselWidget[], includeList: string[]): CarouselItem[] {
    const params = items
        .filter((x) => x.widget_parameters !== null)
        .map((x) => x.widget_parameters) as GqlCarouselWidget[];
    const carousel_items = params.filter((item) => {
        return includeList.every((key) => item[key] && item[key] !== null);
    }) as CarouselItem[];
    return carousel_items.map((item) => {
        return {
            ...item,
            link: stripDomain(item.link),
            image: imagePath(item.image),
            image_mobile: imagePath(item.image_mobile),
        };
    });
}

export function verifyCategories(items: widgets_featuredCategoryWidget[], includeList: string[]): CategoryItem[] {
    const params = items
        .filter((x) => x.widget_parameters !== null)
        .map((x) => x.widget_parameters) as GqlCategoriesWidget[];
    const categoryItems = params.filter((item) => {
        return includeList.every((key) => item[key] && item[key] !== null);
    }) as CategoryItem[];
    return categoryItems.map((cat) => {
        return {
            ...cat,
            image: imagePath(cat.image),
            link: stripDomain(cat.link),
        };
    });
}

export const verifyBrandCarousel = (items: widgets_brandWidget[]) => {
    const params = items
        .filter((x) => x.widget_parameters !== null)
        .map((x) => x.widget_parameters) as GqlBrandCarouselWidget[];
    return params;
};

export const verifyThumbWidget = (items: widgets_thumbWidget[]) =>
    items.filter((x) => x.widget_parameters !== null).map((x) => x.widget_parameters);

export function verifyTeasers(items: widgets_teaserWidget[], includeList: string[]): Teaser[] {
    const params = items
        .filter((x) => x.widget_parameters !== null)
        .map((x) => x.widget_parameters) as GqlTeasersWidget[];
    return params.filter((item) => {
        return includeList.every((key) => item[key] && item[key] !== null);
    }) as Teaser[];
}

export function verifyTrusted(items: widgets_trustedByWidget[], includeList: string[]) {
    const params = items
        .filter((x) => x.widget_parameters !== null)
        .map((x) => x.widget_parameters) as GqlTrustedByWidget[];
    return params.map((item) => {
        return {
            ...item,
            image: imagePath(item.image),
            link: stripDomain(item.link),
        };
    });
}

export function verifyInfo(items: widgets_infoBlockWidget[], includeList: string[]): InfoItem[] {
    const params = items.filter((x) => x.widget_parameters !== null).map((x) => x.widget_parameters) as GqlInfoWidget[];
    const infoItems = params.filter((item) => {
        return includeList.every((key) => item[key] && item[key] !== null);
    }) as InfoItem[];
    return infoItems.map((item) => {
        return {
            ...item,
            image: imagePath(item.image),
            image1: imagePath(item.image1),
            image2: imagePath(item.image2),
            image3: imagePath(item.image3),
            link: item.link,
        };
    });
}

export function verifyDeals(items: widgets_homepageCta[], includeList: string[]): Deal[] {
    const params = items.filter((x) => x !== null).map((x) => x) as GqlCtaWidget[];
    const dealItems = params.filter((item) => {
        return includeList.every((key) => item[key] && item[key] !== null);
    });
    return dealItems.map((item) => {
        return {
            imageDesk: item.image ?? '',
            imageMob: item.image ?? '',
            title: item.title ?? '',
            link: item.url ?? '',
        };
    });
}

type GqlCarouselWidget = widgets_carouselWidget_widget_parameters;
type GqlCategoriesWidget = widgets_featuredCategoryWidget_widget_parameters;
type GqlBrandCarouselWidget = widgets_brandWidget_widget_parameters;
type GqlTeasersWidget = widgets_teaserWidget_widget_parameters;
type GqlTrustedByWidget = widgets_trustedByWidget_widget_parameters;
type GqlInfoWidget = widgets_infoBlockWidget_widget_parameters;
type GqlCtaWidget = widgets_homepageCta;
