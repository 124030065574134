import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { HomeDeals, Deal } from 'src/components/Home/HomeDeals';
import Bugsnag from '@bugsnag/js';
import { Helmet } from 'react-helmet-async';
import { ReasonsToShopWithUs } from 'src/components/ReasonsToShopWithUs/ReasonsToShopWithUs';
import { FREE_SHIPPING } from 'src/constants/meta';

import { CategoryItem, CategoryList } from '../CategoryList/CategoryList';
import { TrustedBy } from '../../elements/Trusted/TrustedBy';
import { Carousel } from '../../elements/Carousel/Carousel';

import deals from './deals.json';
import {
    widgets,
    widgets_carouselWidget,
    widgets_homepageCta,
    widgets_infoBlockWidget,
    widgets_teaserWidget,
    widgets_trustedByWidget,
    widgets_brandWidget,
    widgets_thumbWidget_widget_parameters,
} from './queries/__generated__/widgets';
import homeQuery from './queries/homepage.graphql';
import { Teaser } from './HomeTeasers';
import { HomeMainWidgets, CarouselItem } from './HomeMainWidgets';
import { HomeInfo, InfoItem } from './HomeInfo';
import { SevenDeals } from './SevenDeals';
import {
    verifyCarousel,
    verifyCategories,
    verifyInfo,
    verifyTeasers,
    verifyTrusted,
    verifyDeals,
    verifyBrandCarousel,
    verifyThumbWidget,
} from './homeUtils';

type BrandCarouselItem = {
    label: string | null;
    image: string | null;
};

type TrustedByItem = {
    image: string;
    link: string;
};

type HomeContextType = {
    carousel: CarouselItem[];
    categories: CategoryItem[];
    brandCarousel: BrandCarouselItem[];
    sevenDeals: Teaser[];
    trusted: TrustedByItem[];
    info: InfoItem[];
    deals: Deal[];
    bannerThumbWidget: widgets_thumbWidget_widget_parameters[];
};

export const HomeContext = React.createContext<HomeContextType>({
    carousel: [],
    categories: [],
    brandCarousel: [],
    sevenDeals: [],
    trusted: [],
    info: [],
    deals: [],
    bannerThumbWidget: [],
});

export const Home: React.FC = (props) => {
    const { error, data, loading } = useQuery<widgets>(homeQuery, { ssr: useDeps().env.SSR_GQL });

    if (error) {
        Bugsnag.notify('could not fetch Home page widgets', (event) => {
            event.addMetadata('errorObject', error);
        });
        return null;
    }

    if (loading) return null;

    if (!data) {
        Bugsnag.notify('Widgets are missing', (event) => {
            event.addMetadata('data', { data: data });
        });
        return null;
    }

    const carouselKeys = ['title', 'image', 'description', 'image_mobile', 'link_text', 'link'];
    const categoryKeys = ['title', 'image', 'link'];
    const teaserKeys = ['title', 'description', 'link', 'link_text'];
    const trustedByKeys = ['image', 'link'];
    const infoKeys = ['title', 'description', 'image'];
    const dealKeys = ['title', 'url', 'image'];

    const dealsData = verifyDeals(data.homepageCta as widgets_homepageCta[], dealKeys);

    const api: HomeContextType = {
        carousel: verifyCarousel(data.carouselWidget as widgets_carouselWidget[], carouselKeys),
        bannerThumbWidget: verifyThumbWidget(data.bannerThumbWidget),
        categories: verifyCategories(data.featuredCategoryWidget as [], categoryKeys),
        brandCarousel: verifyBrandCarousel(data.brandWidget as widgets_brandWidget[]),
        // teaser widget used for seven deals
        sevenDeals: verifyTeasers(data.teaserWidget as widgets_teaserWidget[], teaserKeys),
        trusted: verifyTrusted(data.trustedByWidget as widgets_trustedByWidget[], trustedByKeys),
        info: verifyInfo(data.infoBlockWidget as widgets_infoBlockWidget[], infoKeys),
        deals: (dealsData.length > 0 && dealsData) || deals.deals,
    };

    return (
        <HomeContext.Provider value={api}>
            <>
                <Helmet titleTemplate="%s">
                    <title>Personalised Work Clothing & Work Uniforms | Workwear Express</title>
                    <meta
                        name="description"
                        content={`Your Company Logo On 2 Million+ Items ✓ 24 Hour Dispatch ✓ Free Delivery Over ${FREE_SHIPPING} ✓ Free Returns ✓ 600000 Happy Customers`}
                    />
                </Helmet>

                {props.children}
            </>
        </HomeContext.Provider>
    );
};

export function BrandCarousel() {
    const { brandCarousel } = useContext(HomeContext);
    return <Carousel items={brandCarousel} mainTitle="Top Brands" moreBlocks />;
}

export function HomeCarouselWrapper() {
    return <HomeMainWidgets />;
}

export function DealsLazy() {
    const { deals } = useContext(HomeContext);
    return <HomeDeals deals={deals} />;
}

export function CategoryListLazy() {
    const { categories } = useContext(HomeContext);
    return <CategoryList categories={categories} />;
}

export function ReasonsToShopWithUsLazy() {
    return <ReasonsToShopWithUs />;
}

export function TrustedLazy() {
    const { trusted } = useContext(HomeContext);
    return <TrustedBy items={trusted} />;
}

export function InfoLazy() {
    const { info } = useContext(HomeContext);
    return <HomeInfo items={info} />;
}

export function SevenDealsLazy() {
    const { sevenDeals } = useContext(HomeContext);
    if (sevenDeals.length === 0) {
        return;
    }
    return <SevenDeals items={sevenDeals} />;
}
