import React from 'react';
import { DispatchProp } from 'react-redux';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { Home, HomeCarouselWrapper } from 'src/components/Home/Home';
import { LazyHydrate } from 'src/components/AppShell/LazyHydrate';
import { DefaultMeta } from 'src/components/AppShell/Head';
import { FooterNewsletterConnected } from 'src/components/Footer/FooterNewsletter';

import { Container, Wrapper } from '../../components/Layout';
import '../../scss/_root-component.scss';

import { helmet } from './home';

type HomePageProps = {
    id: number;
    outdated: boolean;
    version: string | undefined;
} & DispatchProp<any>;

export const HomePage: React.FC<HomePageProps> = () => {
    useSetResolved();
    return (
        <div style={{ background: 'white' }}>
            <Home>
                <DefaultMeta data={helmet} withoutPrefix />
                <Wrapper variant="page-inner">
                    <HomeCarouselWrapper />
                    <Container>
                        <LazyHydrate load={() => require('../../components/Home/Home').CategoryListLazy} />
                        <LazyHydrate load={() => require('../../components/Home/Home').BrandCarousel} />
                    </Container>
                </Wrapper>
                <Wrapper variant="page-inner" lighterBackground overflowHidden>
                    <Container>
                        <LazyHydrate load={() => require('../../components/Home/Home').ReasonsToShopWithUsLazy} />
                        <LazyHydrate load={() => require('../../components/Home/Home').TrustedLazy} />
                    </Container>
                </Wrapper>
                <Wrapper variant="page-inner" overflowHidden>
                    <Container>
                        <LazyHydrate load={() => require('../../components/Home/Home').InfoLazy} />
                    </Container>
                </Wrapper>
                <FooterNewsletterConnected />
            </Home>
        </div>
    );
};

export default HomePage;
